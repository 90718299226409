import { useEffect, useState } from 'react'
import { Button, Image } from '@nextui-org/react'
import { Icon } from '@iconify/react'
import { AssetWithBalance } from './TokenSelector.tsx'
import { numberWithCommas } from '../utils/number.ts'
import { Body4, Subtitle3 } from './Typography.tsx'
import { getShortAddress, getTokenAddress } from '../utils/token.ts'

export default function BasicTokenInfo({
  token,
  onCopy,
  isCopying,
}: {
  token: AssetWithBalance
  onCopy: (id: string) => void
  isCopying: boolean
}) {
  const [src, setSrc] = useState(token.logoUrl || '/images/404.svg')

  useEffect(() => {
    setSrc(token.logoUrl || '/images/404.svg')
  }, [token, onCopy])

  const valueCopy = getTokenAddress(token)

  return (
    <div className="flex w-full items-center gap-2">
      <Image
        width={32}
        height={32}
        src={src}
        onError={() => setSrc('/images/404.svg')}
        className="flex h-[32px] min-h-[32px] w-[32px] min-w-[32px] rounded-full bg-white"
        disableSkeleton
      />
      <div className="flex grow flex-col gap-1 overflow-hidden">
        <div className="flex items-baseline gap-1">
          <Subtitle3 className="text-white">{token.symbol}</Subtitle3>
          <Button
            variant="light"
            className="h-fit w-fit min-w-fit gap-1 self-center rounded-none p-0 font-normal data-[hover]:bg-transparent"
            onPress={() => onCopy(valueCopy)}
            disableAnimation
            disableRipple
          >
            {isCopying ? (
              <>
                <Body4 className="overflow-hidden text-ellipsis whitespace-nowrap pt-[1.5px] text-baseGrey">
                  {getShortAddress(valueCopy)}
                </Body4>
                <Icon icon="material-symbols:check" fontSize={16} className="text-baseGrey" />
              </>
            ) : (
              <>
                <Body4 className="overflow-hidden text-ellipsis whitespace-nowrap pt-[1.5px] text-baseGrey">
                  {getShortAddress(valueCopy)}
                </Body4>
                <Icon icon="ph:copy" fontSize={16} className="text-baseGrey" />
              </>
            )}
          </Button>
        </div>
        <Body4 className="w-full flex-1 overflow-hidden text-ellipsis whitespace-nowrap text-start text-baseGrey">
          {token.name}
        </Body4>
      </div>
      <div className="flex flex-col items-end justify-between gap-1">
        <Body4 className="text-white">
          {token.fractionalBalance ? token.fractionalBalance?.toSignificant(6) : undefined}
        </Body4>
        <Body4 className="text-baseGrey">
          {token.fractionalBalanceUsd
            ? `~$${numberWithCommas(token.fractionalBalanceUsd?.toSignificant(6))}`
            : undefined}
        </Body4>
      </div>
    </div>
  )
}
