import { Link } from '@nextui-org/react'
import { Icon } from '@iconify/react'
import { DocumentIcon } from './Icons.tsx'

export default function Footer() {
  return (
    <footer className="z-[1] flex w-full flex-1 items-end">
      <div className="flex w-full content-center items-center justify-center bg-black/80 px-[60px] py-3 lg:px-[30px] md:static md:px-[16px] sm:justify-center">
        <div className="flex items-center gap-4">
          <div className="flex items-center gap-5">
            <Link
              isBlock
              href="https://x.com/mosaicagg"
              color="foreground"
              className="p-1 text-baseGrey"
              disableAnimation
              size="sm"
              isExternal
            >
              <Icon icon="ri:twitter-x-fill" fontSize={24} />
            </Link>
            <Link
              isBlock
              href="https://discord.gg/mosaicagg"
              color="foreground"
              className="p-1 text-baseGrey"
              disableAnimation
              size="sm"
              isExternal
            >
              <Icon icon="ic:baseline-discord" fontSize={28} className="stroke-2" />
            </Link>
            <Link
              isBlock
              href="https://docs.mosaic.ag"
              color="foreground"
              className="p-1 text-baseGrey"
              disableAnimation
              size="sm"
              isExternal
            >
              <DocumentIcon size={24} />
            </Link>
          </div>
        </div>
      </div>
    </footer>
  )
}
