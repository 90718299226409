import { Button, Image, Modal, ModalContent, Spacer } from '@nextui-org/react'
import { CloseIcon } from '../Icons.tsx'
import { Body4, Subtitle1, Subtitle2 } from '../Typography.tsx'
import { useIsSm } from '../../hooks/useMedia.ts'
import { bitget, getWalletImagePath, nightly, okx, razor } from '../../constants/wallet.ts'
import { MODAL_LIST } from './constant.ts'
import { useModal } from '../../provider/ModalProvider.tsx'
import { IDefaultWallet } from '@razorlabs/wallet-kit'
import useMovementWallet from '../../hooks/useMovementWallet.ts'

export default function ModalConnectWallet() {
  const { globalModal, isModalOpen, onOpenChangeModal, onCloseModal } = useModal()
  const isOpen = globalModal === MODAL_LIST.CONNECT_WALLET && isModalOpen
  const isSm = useIsSm()

  const { select: _connect, connecting, connected } = useMovementWallet()

  const connect = async (wallet: Readonly<IDefaultWallet>) => {
    await _connect(wallet.name)
  }

  if (connected) return null

  return (
    <>
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChangeModal}
        placement="top-center"
        backdrop="blur"
        hideCloseButton
        onClose={onCloseModal}
        size={isSm ? 'full' : undefined}
      >
        <ModalContent
          className={
            'mt-[140px] max-w-[450px] rounded-lg bg-baseGrey1 text-foreground dark sm:mt-[unset]' +
            ' ' +
            (isSm ? 'h-fit max-h-[70vh] min-h-[200px] self-end' : '')
          }
        >
          <>
            <div className="flex items-center justify-between border-b-[0.5px] border-borderGrey2 px-5 py-3">
              <Subtitle1 className="text-baseGrey">Connect your wallet</Subtitle1>
              <Button isIconOnly variant="light" className="h-[20px] w-[20px] min-w-fit p-0" onPress={onCloseModal}>
                <CloseIcon size={20} />
              </Button>
            </div>

            <Spacer y={4} />

            <div className="m-auto flex justify-between">
              <Button
                variant="light"
                className="flex h-[42px] w-[170px] items-center justify-start border-[0.5px] border-transparent p-0 px-4 data-[hover]:border-primaryHover data-[hover]:bg-[#433B28]"
                disableRipple
                disableAnimation
                isDisabled={connecting}
                onPress={() => connect(razor)}
              >
                <Image src={getWalletImagePath(razor.name)} className="w-[26px] min-w-[26px] rounded" />
                <Subtitle2 className="text-primaryHover">{razor.name}</Subtitle2>
              </Button>
              <Button
                variant="light"
                className="flex h-[42px] w-[170px] items-center justify-start border-[0.5px] border-transparent p-0 px-4 data-[hover]:border-primaryHover data-[hover]:bg-[#433B28]"
                disableRipple
                disableAnimation
                isDisabled={connecting}
                onPress={() => connect(nightly)}
              >
                <Image src={getWalletImagePath(nightly.name)} className="w-[26px] min-w-[26px] rounded" />
                <Subtitle2 className="text-primaryHover">{nightly.name}</Subtitle2>
              </Button>
            </div>

            <Spacer y={2} />

            <div className="m-auto flex justify-between">
              <Button
                variant="light"
                className="flex h-[42px] w-[170px] items-center justify-start border-[0.5px] border-transparent p-0 px-4 data-[hover]:border-primaryHover data-[hover]:bg-[#433B28]"
                disableRipple
                disableAnimation
                isDisabled={connecting}
                onPress={() => connect(okx)}
              >
                <Image src={getWalletImagePath(okx.name)} className="w-[26px] min-w-[26px] rounded" />
                <Subtitle2 className="text-primaryHover">{okx.name}</Subtitle2>
              </Button>
              <Button
                variant="light"
                className="flex h-[42px] w-[170px] items-center justify-start border-[0.5px] border-transparent p-0 px-4 data-[hover]:border-primaryHover data-[hover]:bg-[#433B28]"
                disableRipple
                disableAnimation
                isDisabled={connecting}
                onPress={() => connect(bitget)}
              >
                <Image src={getWalletImagePath(bitget.name)} className="w-[26px] min-w-[26px] rounded" />
                <Subtitle2 className="text-primaryHover">{bitget.name}</Subtitle2>
              </Button>
            </div>

            <Spacer y={4} />

            <div className="flex w-full items-center justify-center border-t-[0.5px] border-borderGrey2 px-5 py-3">
              <Body4 className="w-[35ch] text-center">
                By connecting your wallet, you agree to our <span className="text-primary">Terms of Use</span> and{' '}
                <span className="text-primary">Privacy Policy</span>.
              </Body4>
            </div>
          </>
        </ModalContent>
      </Modal>
    </>
  )
}
