import { Aptos, AptosConfig } from '@aptos-labs/ts-sdk'
import { DEFAULT_ASSETS } from './asset.ts'
import { SUPPORTED_POOLS } from './pool.ts'

export const ENV = import.meta.env.VITE_ENV
export const APP_VERSION = 18 // Pump version to purge state.

export const AGGREGATOR_URL = import.meta.env.VITE_AGGREGATOR_URL
export const AGGREGATOR_API_KEY = import.meta.env.VITE_AGGREGATOR_API_KEY

export const MOVEMENT_RPC_URL = 'https://aptos.testnet.porto.movementlabs.xyz/v1'

const aptosConfig = new AptosConfig({
  fullnode: MOVEMENT_RPC_URL,
  indexer: 'https://indexer.testnet.porto.movementnetwork.xyz/v1/graphql',
})
export const aptos = new Aptos(aptosConfig)

export const BIP_BASE = 10_000
export const DEFAULT_SLIPPAGE_BPS = 100

export const PRELOAD_IMAGES = [
  ...Object.values(DEFAULT_ASSETS).map((a) => a?.logoUrl || ''),
  ...Object.values(SUPPORTED_POOLS).map((p) => p?.logoUrl || ''),
].filter((i) => i)

export const MOVEMENT_CHAIN_ID = 27

export const REFRESH_BALANCE_INTERVAL = 10_000
export const NETWORK_STATUS_CHECKING_INTERVAL = 10_000
