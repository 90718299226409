import { useCallback } from 'react'
import { aptos } from '../constants'
import { useAppDispatch, useAppSelector } from '../redux/hooks'
import { addTokensToFollow } from '../redux/slices/token'
import { updateBalance, WalletBalance } from '../redux/slices/wallet'
import useMovementWallet from './useMovementWallet.ts'
import { Asset } from '../constants/asset.ts'
import useFullTokens from './useFullTokens.ts'

export interface GetAccountCoinResourceResponse {
  type: string
  data: {
    coin: {
      value: string
    }
  }
}

export default function useRefreshBalanceFn() {
  const dispatch = useAppDispatch()
  const { account } = useMovementWallet()

  const { data: fullTokenData } = useFullTokens()

  const fn = useCallback(async () => {
    if (!account) return
    try {
      const data = await aptos.getAccountCoinsData({
        accountAddress: account.address,
        options: {
          where: {
            owner_address: { _eq: account.address },
          },
          offset: 0,
          limit: 100,
        },
      })
      const accountCoinsData: WalletBalance = {}
      data.forEach((item) => {
        if (item.asset_type && fullTokenData) {
          const tokenInfo = Object.values(fullTokenData).find(
            (e) => e?.coinType === item.asset_type || e?.id === item.asset_type,
          )
          if (tokenInfo) accountCoinsData[tokenInfo.id] = item.amount
        }
      })

      // const response = await axios<GetAccountCoinResourceResponse[]>(
      //   `${MOVEMENT_RPC_URL}/accounts/${account.address}/resources`,
      // )

      // if (response.status !== 200) return
      // response.data.forEach((item) => {
      //   if (item.type.startsWith('0x1::coin::CoinStore')) {
      //     const matches = item.type.match(/0x1::coin::CoinStore<(.*)>/)
      //     if (!matches || matches.length !== 2) return
      //     const coinType = matches[1]
      //     if (item.data.coin.value != '0') {
      //       accountCoinsData[coinType] = item.data.coin.value
      //     }
      //   }
      // })

      dispatch(updateBalance(accountCoinsData))
      dispatch(addTokensToFollow(Object.keys(accountCoinsData).map((coinType) => coinType)))
    } catch (err) {
      console.error(err)
    }
  }, [account, dispatch, fullTokenData])

  return fn
}

export const useGetTokenBalance = () => {
  const { balance } = useAppSelector((state) => state.wallet)
  return useCallback((token: Asset | undefined) => balance?.[token?.id ?? ''], [balance])
}

export const useTokenBalance = (token: Asset | undefined) => {
  const getBalance = useGetTokenBalance()
  return getBalance(token)
}
