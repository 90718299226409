import { ValueOf } from '../types.ts'
import { SUPPORTED_POOLS } from '../constants/pool.ts'
import { Asset } from '../constants/asset.ts'

export interface Hop {
  srcAsset: Asset
  dstAsset: Asset
  srcAmount: string
  dstAmount: string
  pool: ValueOf<typeof SUPPORTED_POOLS>
}

export interface PoolWithAmount {
  amount: string
  pool: ValueOf<typeof SUPPORTED_POOLS>
}

export interface Route {
  srcAsset: Asset
  dstAsset: Asset
  srcAmount: string
  dstAmount: string
  pools: PoolWithAmount[]
  next: Route[]
}

// export const sampleRoutes: Route[] = [
//   {
//     srcCoin: MOVE,
//     dstCoin: USDC,
//     srcAmount: new Fraction(100),
//     dstAmount: new Fraction(500),
//     pools: [{ pool: POOLS.razor_swap, amount: new Fraction(100) }],
//     next: [],
//   },
//   {
//     srcCoin: MOVE,
//     dstCoin: USDT,
//     srcAmount: new Fraction(300),
//     dstAmount: new Fraction(600),
//     pools: [
//       { amount: new Fraction(150), pool: POOLS.razor_swap },
//       { amount: new Fraction(50), pool: POOLS.liquid_swap },
//       { amount: new Fraction(100), pool: POOLS.meridian_stable },
//     ],
//     next: [
//       {
//         srcCoin: USDT,
//         dstCoin: WETH,
//         srcAmount: new Fraction(400),
//         dstAmount: new Fraction(40),
//         pools: [{ amount: new Fraction(400), pool: POOLS.razor_swap }],
//         next: [
//           {
//             srcCoin: WETH,
//             dstCoin: USDC,
//             srcAmount: new Fraction(40),
//             dstAmount: new Fraction(400),
//             pools: [
//               { amount: new Fraction(25), pool: POOLS.razor_swap },
//               { amount: new Fraction(15), pool: POOLS.meridian_stable },
//             ],
//             next: [],
//           },
//         ],
//       },
//       {
//         srcCoin: USDT,
//         dstCoin: USDC,
//         srcAmount: new Fraction(600),
//         dstAmount: new Fraction(600),
//         pools: [{ amount: new Fraction(600), pool: POOLS.razor_swap }],
//         next: [],
//       },
//       {
//         srcCoin: USDT,
//         dstCoin: WBTC,
//         srcAmount: new Fraction(500),
//         dstAmount: new Fraction(25),
//         pools: [{ amount: new Fraction(500), pool: POOLS.razor_swap }],
//         next: [
//           {
//             srcCoin: WBTC,
//             dstCoin: USDC,
//             srcAmount: new Fraction(25),
//             dstAmount: new Fraction(500),
//             pools: [
//               { amount: new Fraction(15), pool: POOLS.razor_swap },
//               { amount: new Fraction(10), pool: POOLS.meridian_stable },
//             ],
//             next: [],
//           },
//         ],
//       },
//     ],
//   },
//   {
//     srcCoin: MOVE,
//     dstCoin: WETH,
//     srcAmount: new Fraction(7200),
//     dstAmount: new Fraction(1440),
//     pools: [
//       { amount: new Fraction(5000), pool: POOLS.razor_swap },
//       { amount: new Fraction(2200), pool: POOLS.liquid_swap },
//     ],
//     next: [
//       {
//         srcCoin: WETH,
//         dstCoin: USDC,
//         srcAmount: new Fraction(1440),
//         dstAmount: new Fraction(14400),
//         pools: [
//           { amount: new Fraction(1000), pool: POOLS.razor_swap },
//           { amount: new Fraction(200), pool: POOLS.liquid_swap },
//           { amount: new Fraction(240), pool: POOLS.meridian_stable },
//         ],
//         next: [],
//       },
//     ],
//   },
// ]

export function pathToRoutes(paths: Hop[][]): Route[] {
  const routes: Route[] = []

  for (const path of paths) {
    let currentRoute = routes

    for (const hop of path) {
      const existingRoute = currentRoute.find((r) => r.dstAsset.id === hop.dstAsset.id)

      if (existingRoute) {
        updateExistingRoute(existingRoute, hop)
        currentRoute = existingRoute.next
      } else {
        const newRoute = createNewRoute(hop)
        currentRoute.push(newRoute)
        currentRoute = newRoute.next
      }
    }
  }

  return routes
}

function updateExistingRoute(route: Route, hop: Hop): void {
  route.srcAmount = (BigInt(route.srcAmount) + BigInt(hop.srcAmount)).toString()
  route.dstAmount = (BigInt(route.dstAmount) + BigInt(hop.dstAmount)).toString()
  updateOrAddPool(route.pools, hop)
}

function createNewRoute(hop: Hop): Route {
  return {
    srcAsset: hop.srcAsset,
    dstAsset: hop.dstAsset,
    srcAmount: hop.srcAmount,
    dstAmount: hop.dstAmount,
    pools: [{ amount: hop.srcAmount, pool: hop.pool }],
    next: [],
  }
}

function updateOrAddPool(pools: PoolWithAmount[], hop: Hop): void {
  const existingPool = pools.find((p) => p.pool?.name === hop.pool?.name)
  if (existingPool) {
    existingPool.amount = (BigInt(existingPool.amount) + BigInt(hop.srcAmount)).toString()
  } else {
    pools.push({ pool: hop.pool, amount: hop.srcAmount })
  }
}
