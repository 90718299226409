import { Button, Image, Link, Modal, ModalContent, Tab, Tabs } from '@nextui-org/react'
import React, { useCallback, useMemo, useState } from 'react'

import { Icon } from '@iconify/react'
import { useCopyToClipboard } from 'usehooks-ts'
import useFullTokens from '../../hooks/useFullTokens.ts'
import { useAppSelector } from '../../redux/hooks'
import { ITransactionHistory } from '../../redux/slices/user.ts'
import { PartialRecord } from '../../types.ts'
import { Fraction } from '../../utils/fraction.ts'
import { divpowToFraction, mulpowToFraction, numberWithCommas } from '../../utils/number.ts'
import { getDisplayPeriod } from '../../utils/time.ts'
import BasicTokenInfo from '../BasicTokenInfo.tsx'
import { MODAL_LIST } from './constant.ts'
import { Body2, Body3, Body4, Headline2, Subtitle3 } from '../Typography.tsx'
import { useModal } from '../../provider/ModalProvider.tsx'
import { AssetWithBalance } from '../TokenSelector.tsx'
import { Asset } from '../../constants/asset.ts'
import { getWalletImagePath } from '../../constants/wallet.ts'
import useMovementWallet from '../../hooks/useMovementWallet.ts'
import { getShortAddress, getTokenAddress } from '../../utils/token.ts'

export interface TransactionHistoryWithLogoUrl extends ITransactionHistory {
  tokenLogoIn: string
  tokenLogoOut: string
}
const ModalAssetsAndActivities: React.FC = () => {
  const { globalModal, isModalOpen, onOpenChangeModal, onCloseModal } = useModal()
  const isOpen = globalModal === MODAL_LIST.ACTIVITIES && isModalOpen
  const [isShowBalance, setShowBalance] = useState(false)
  const { account, name, disconnect } = useMovementWallet()
  const { balance } = useAppSelector((state) => state.wallet)
  const followingTokenData = useAppSelector((state) => state.token.followingTokenData)

  const { data: fullTokenData } = useFullTokens()
  const followingPriceData = useAppSelector((state) => state.price.followingPriceData)
  const assets = useMemo(() => {
    const res: PartialRecord<string, AssetWithBalance> = {}
    for (const key of Object.keys(balance)) {
      let fractionalBalance: Fraction | undefined
      const tokenBalance = balance[key]
      const tokenInfo = followingTokenData?.[key] || fullTokenData?.[key]

      if (fullTokenData && tokenBalance && tokenInfo) {
        if (account?.address && tokenBalance) {
          fractionalBalance = divpowToFraction(tokenBalance, tokenInfo.decimals)
        }
        let fractionalBalanceUsd: Fraction | undefined

        const price = followingPriceData?.[key]

        if (fractionalBalance && price) {
          const fractionalPrice = mulpowToFraction(price, tokenInfo.decimals)
          fractionalBalanceUsd = fractionalBalance.multiply(fractionalPrice)
        }
        res[key] = {
          ...tokenInfo,
          fractionalBalance,
          fractionalBalanceUsd,
          isFollowing: true,
        }
      }
    }
    return res
  }, [balance, fullTokenData, account?.address, followingPriceData, followingTokenData])

  const assetTokenList = useMemo(() => {
    const list = Object.values(assets) as AssetWithBalance[]
    list.sort((a: AssetWithBalance, b: AssetWithBalance) => {
      const x = a.fractionalBalanceUsd ?? new Fraction(0)
      const y = b.fractionalBalanceUsd ?? new Fraction(0)
      if (x.lessThan(y)) {
        return 1
      } else if (x.greaterThan(y)) {
        return -1
      }
      return a.symbol.localeCompare(b.symbol)
    })
    return list
  }, [assets])

  const [copiedId, copy] = useCopyToClipboard()
  const [isCopying, setIsCopying] = useState(false)
  const onCopy = useCallback(
    async (id: string) => {
      try {
        setIsCopying(true)
        await copy(id)
        await new Promise((resolve) => setTimeout(resolve, 500))
      } finally {
        setIsCopying(false)
      }
    },
    [copy],
  )
  const txHistoryMap = useAppSelector((state) => state.user.txHistoryMap)
  const renderTransactionHistories = useMemo(() => {
    const transactionHistories = Object.values(txHistoryMap) as ITransactionHistory[]
    const followingTokenDataList = Object.values(followingTokenData) as Asset[]
    return transactionHistories.map((transactionHistory) => {
      const tokenLogoIn =
        followingTokenDataList.find((token) => token.id === transactionHistory.tokenInAddress)?.logoUrl ?? ''
      const tokenLogoOut =
        followingTokenDataList.find((token) => token.id === transactionHistory.tokenOutAddress)?.logoUrl ?? ''
      const res: TransactionHistoryWithLogoUrl = {
        txHash: transactionHistory.txHash,
        isSuccess: transactionHistory.isSuccess,
        details: transactionHistory.details,
        tokenInSymbol: transactionHistory.tokenInSymbol,
        tokenOutSymbol: transactionHistory.tokenOutSymbol,
        readableAmountIn: transactionHistory.readableAmountIn,
        readableAmountOut: transactionHistory.readableAmountOut,
        tokenLogoIn: tokenLogoIn,
        tokenLogoOut: tokenLogoOut,
        timestamp: transactionHistory.timestamp,
        tokenInAddress: transactionHistory.tokenInAddress,
        tokenOutAddress: transactionHistory.tokenOutAddress,
      }
      return res
    })
  }, [txHistoryMap, followingTokenData])

  const totalBalanceInUSD = useMemo(() => {
    if (!assets) return 0
    return (Object.values(assets) as AssetWithBalance[]).reduce(
      (prev, curr) => curr.fractionalBalanceUsd?.add(prev) ?? prev,
      new Fraction(0),
    )
  }, [assets])

  if (!account) return null

  return (
    <Modal
      scrollBehavior="inside"
      isOpen={isOpen}
      onOpenChange={onOpenChangeModal}
      placement="center"
      backdrop="transparent"
      size="full"
      classNames={{
        wrapper: 'flex justify-end',
      }}
      motionProps={{
        variants: {
          enter: {
            x: 0,
            opacity: 1,
            transition: {
              duration: 0.3,
              ease: 'easeOut',
            },
          },
          exit: {
            x: 50,
            opacity: 0,
            transition: {
              duration: 0.2,
              ease: 'easeIn',
            },
          },
        },
      }}
      className="h-screen max-h-screen w-full max-w-sm"
      hideCloseButton
    >
      <ModalContent className="flex min-h-fit flex-col !rounded-xl bg-baseGrey1 pt-6 dark">
        <div className="mb-6 flex flex-row justify-between px-6">
          <div className="flex flex-row items-center gap-2">
            {name && <Image src={getWalletImagePath(name)} width={18} />}
            <Subtitle3 className="leading-5 text-white">{getShortAddress(account.address)}</Subtitle3>
            <div className="flex gap-2">
              <Button
                className="h-[20px] w-[20px] min-w-0 bg-transparent p-0"
                isIconOnly
                disableAnimation
                disableRipple
                onPress={async () => {
                  await onCopy(account.address)
                }}
              >
                {isCopying && copiedId === account.address ? (
                  <Icon icon="iconamoon:check" fontSize={20} className="text-baseGrey" />
                ) : (
                  <Icon icon="iconamoon:copy" fontSize={20} className="text-baseGrey" />
                )}
              </Button>
              <Link
                href={`https://explorer.movementnetwork.xyz/account/${account.address}?network=porto+testnet`}
                isExternal
                className="text-baseGrey"
              >
                <Icon icon="iconamoon:link-external" fontSize={20} color="text-baseGrey" />
              </Link>
              <Button
                className="h-[20px] w-[20px] min-w-0 flex-none justify-end bg-transparent p-0"
                isIconOnly
                onPress={() => {
                  onCloseModal()
                  void disconnect()
                }}
              >
                <Icon icon="ph:power-bold" fontSize={20} className="text-baseGrey" />
              </Button>
            </div>
          </div>
          <Button
            className="h-[20px] w-[20px] min-w-0 flex-none justify-end bg-transparent p-0"
            isIconOnly
            onPress={onCloseModal}
          >
            <Icon icon="mdi:close" fontSize={20} className="text-baseGrey" />
          </Button>
        </div>
        <div className="mx-6 mb-[24px] flex flex-col gap-1 rounded-[10px] bg-basicBg p-3">
          <div className="flex items-center gap-2">
            <Body4 className="text-baseGrey">Total Balance</Body4>
            {isShowBalance ? (
              <Icon
                icon="mdi:eye-off-outline"
                fontSize={20}
                className="text-baseGrey"
                onClick={() => setShowBalance(!isShowBalance)}
              />
            ) : (
              <Icon
                icon="lucide:eye"
                fontSize={20}
                className="text-baseGrey"
                onClick={() => setShowBalance(!isShowBalance)}
              />
            )}
          </div>
          <Headline2 className="text-white">
            {isShowBalance
              ? totalBalanceInUSD
                ? `$${numberWithCommas(totalBalanceInUSD?.toSignificant(6), false)}`
                : undefined
              : '*** ***'}
          </Headline2>
        </div>
        <div className="flex w-full flex-1 flex-col overflow-auto dark">
          <Tabs
            radius="lg"
            variant="light"
            size="md"
            color="primary"
            className="tab-container pl-6"
            classNames={{
              tabList: 'rounded-lg p-0',
              cursor: 'rounded-lg px-0',
            }}
          >
            <Tab key="assets" title="Assets" className="h-[32px]">
              <div className="h-fit bg-baseGrey1">
                {assetTokenList.length === 0 ? (
                  <Body2 className="pt-4 text-center">No asset found</Body2>
                ) : (
                  assetTokenList.map((assetToken) => (
                    <AssetRow
                      key={assetToken.id}
                      token={assetToken}
                      onCopy={onCopy}
                      isCopying={isCopying}
                      copiedId={copiedId ?? ''}
                    />
                  ))
                )}
              </div>
            </Tab>
            <Tab key="activity" title="Activity" className="h-[32px]">
              <div>
                {renderTransactionHistories.length === 0 ? (
                  <Body2 className="mt-16 h-full text-center text-white">No activity found</Body2>
                ) : (
                  renderTransactionHistories
                    .sort((a, b) => b.timestamp - a.timestamp)
                    .map((transactionHistory) => {
                      return <ActivityRow key={transactionHistory.timestamp} transactionHistory={transactionHistory} />
                    })
                )}
              </div>
            </Tab>
          </Tabs>
        </div>
      </ModalContent>
    </Modal>
  )
}

function AssetRow({
  token,
  onCopy,
  copiedId,
  isCopying,
}: {
  token: AssetWithBalance
  onCopy: (id: string) => void
  copiedId: string
  isCopying: boolean
}) {
  const isCopyingThisToken = useMemo(
    () => isCopying && copiedId === getTokenAddress(token),
    [copiedId, isCopying, token],
  )
  return (
    <div className="w-full px-6 py-3 hover:bg-baseBlack">
      <BasicTokenInfo token={token} onCopy={onCopy} isCopying={isCopyingThisToken} />
    </div>
  )
}

function ActivityRow({ transactionHistory }: { transactionHistory: TransactionHistoryWithLogoUrl }) {
  const [tokenInLogoSrc, setTokenInLogoSrc] = useState(transactionHistory.tokenLogoIn || '/images/404.svg')
  const [tokenOutLogoSrc, setTokenOutLogoSrc] = useState(transactionHistory.tokenLogoOut || '/images/404.svg')
  return (
    <div className="flex flex-row items-center justify-between px-6 py-2 hover:bg-baseBlack">
      <div className="flex flex-col gap-1">
        <div className="flex flex-row items-center gap-1">
          <Body3 className="text-white">Swapped</Body3>
          <Link
            href={`https://explorer.movementnetwork.xyz/txn/${transactionHistory.txHash}?network=porto+testnet`}
            isExternal
            className="text-baseGrey"
            showAnchorIcon
          />
        </div>
        <div className="flex flex-wrap items-center gap-1">
          <Image width={16} height={16} src={tokenInLogoSrc} onError={() => setTokenInLogoSrc('/images/404.svg')} />
          <Body4 className="text-baseGrey">
            {transactionHistory.readableAmountIn} {transactionHistory.tokenInSymbol} to{' '}
          </Body4>
          <Image width={16} height={16} src={tokenOutLogoSrc} onError={() => setTokenOutLogoSrc('/images/404.svg')} />
          <Body4 className="text-baseGrey">
            {transactionHistory.readableAmountOut} {transactionHistory.tokenOutSymbol}
          </Body4>
        </div>
      </div>
      <div className="flex flex-col">
        <div className="flex flex-col items-end gap-1">
          <Body3 className="leading-5 text-white">{transactionHistory.isSuccess ? 'Success' : 'Failed'}</Body3>
          <Body4 className="text-baseGrey">{getDisplayPeriod(Date.now() - transactionHistory.timestamp)}</Body4>
        </div>
      </div>
    </div>
  )
}

export default ModalAssetsAndActivities
