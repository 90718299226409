import axios from 'axios'
import { memo, useEffect, useMemo } from 'react'
import useSWR from 'swr'
import { AGGREGATOR_API_KEY, AGGREGATOR_URL } from '../../constants'
import useFullTokens from '../../hooks/useFullTokens'
import { PartialRecord } from '../../types'
import { useAppDispatch, useAppSelector } from '../hooks'
import { addTokensToFollow, updateTokenData } from '../slices/token'
import { DEFAULT_ASSETS, Asset } from '../../constants/asset.ts'

interface GetTokenInfoResponseData {
  tokenById: PartialRecord<string, Asset>
}

interface GetTokenInfoResponse {
  code: number
  message: string
  data: GetTokenInfoResponseData
  requestId: string
}

const useTokenInfoFn = async ({ tokens }: { key: string; tokens: string[] }) => {
  if (tokens.length === 0) return
  const url = `${AGGREGATOR_URL}/v1/tokens?` + tokens.map((t) => `ids[]=${t}`).join('&')
  const response = await axios<GetTokenInfoResponse>(url, {
    headers: {
      'X-API-KEY': AGGREGATOR_API_KEY,
    },
  })
  if (response.status === 200) {
    return response.data
  }
  return undefined
}

function useTokenInfo(tokens: string[]) {
  const {
    data: response,
    error,
    isValidating,
  } = useSWR({ key: 'useTokenInfo', tokens }, useTokenInfoFn, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnMount: false,
    revalidateOnReconnect: false,
  })

  const res = useMemo(() => {
    return {
      isValidating,
      error,
      tokenInfoMap: response?.data.tokenById,
    }
  }, [error, isValidating, response?.data.tokenById])

  return res
}

// function useWhitelistedTokens() {
//     const fn = useCallback(async () => {
//         const url = "https://raw.githubusercontent.com/anqa-ag/aptos-coin-list/main/anqaTokenList.json" // TODO:
//         const response = await axios<RawCoinInfo[]>(url)
//         if (response.status === 200) {
//             return response.data
//         }
//         return undefined
//     }, [])

//     const { data } = useSWR("useWhitelistedTokens", fn)
//     const res = useMemo(() => {
//         if (!data) return undefined
//         const m: PartialRecord<string, RawCoinInfo> = {}
//         for (const asset.ts of data) {
//             m[asset.ts.id] = asset.ts
//         }
//         return m
//     }, [data])
//     return res
// }

function FollowingTokenUpdater() {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(addTokensToFollow(Object.keys(DEFAULT_ASSETS)))
    const newTokenData: PartialRecord<string, Asset> = JSON.parse(JSON.stringify(DEFAULT_ASSETS))
    dispatch(updateTokenData(newTokenData))
  }, [dispatch])

  const followingTokenAddresses = useAppSelector((state) => state.token.followingTokenAddresses)
  const followingTokenData = useAppSelector((state) => state.token.followingTokenData)

  const missingTokenInfoAddresses = useMemo(() => {
    const res: string[] = []
    for (const address of followingTokenAddresses) {
      if (followingTokenData[address] === undefined) {
        res.push(address)
      }
    }
    return res
  }, [followingTokenAddresses, followingTokenData])

  const { tokenInfoMap } = useTokenInfo(missingTokenInfoAddresses)
  useEffect(() => {
    if (tokenInfoMap) {
      const newTokenData: PartialRecord<string, Asset> = {}
      for (const address of Object.keys(tokenInfoMap)) {
        if (tokenInfoMap[address]) {
          newTokenData[address] = {
            ...tokenInfoMap[address],
            whitelisted: false,
            logoUrl: undefined,
          }
        }
      }
      dispatch(updateTokenData(newTokenData))
    }
  }, [dispatch, tokenInfoMap])

  return null
}

function FullTokensUpdater() {
  useFullTokens() // Preload full tokens.

  return null
}

function TokenUpdater() {
  return (
    <>
      <FollowingTokenUpdater />
      <FullTokensUpdater />
    </>
  )
}

const MemoTokenUpdater = memo(TokenUpdater)
export default MemoTokenUpdater
