import { ReactNode, useEffect } from 'react'
import Header from './components/Header.tsx'
import Footer from './components/Footer.tsx'
import { createBrowserRouter, Navigate, RouterProvider, useSearchParams } from 'react-router-dom'
import SwapPage from './pages/swap/SwapPage.tsx'
import { PRELOAD_IMAGES } from './constants'

function NavigateWithParams({ to }: { to: string }) {
  const [params] = useSearchParams()
  return <Navigate to={{ pathname: to, search: `?${params.toString()}` }} />
}

const router = createBrowserRouter([
  {
    path: '/',
    element: <NavigateWithParams to="/swap/MOVE-USDC" />,
  },
  {
    path: '/swap/:pair',
    element: (
      <AppLayout>
        <SwapPage />
      </AppLayout>
    ),
  },
  {
    path: '*',
    element: <NavigateWithParams to="/swap/MOVE-USDC" />,
  },
])

function AppLayout({ children }: { children: ReactNode }) {
  return (
    <>
      <div className="min-h-screen w-screen bg-basicBg dark">
        <div className="flex min-h-screen w-screen flex-col">
          <Header />
          {children}
          <Footer />
        </div>
      </div>
    </>
  )
}

export default function App() {
  useEffect(() => {
    PRELOAD_IMAGES.forEach((i) => {
      const img = new Image()
      img.src = i
    })
  }, [])

  return <RouterProvider router={router} />
}
