import { useCallback, useMemo, useState } from 'react'
import useRefreshBalanceFn from './useRefreshBalanceFn'
import { Ed25519PublicKey, InputEntryFunctionData, isUserTransactionResponse } from '@aptos-labs/ts-sdk'
import invariant from 'invariant'
import { aptos } from '../constants'
import ReactGA from 'react-ga4'
import { sleep } from '../utils/time.ts'
import useSwapNotificationFn from './useSwapNotificationFn.tsx'
import { parse_amount_out_from_event } from '../utils/swap_event.ts'
import useMovementWallet from './useMovementWallet.ts'

export interface SwapState {
  isSwapping: boolean
  txHash: string | undefined
  success: boolean | undefined
}

export default function useSwap() {
  const [{ isSwapping, txHash, success }, setSwapState] = useState<SwapState>({
    isSwapping: false,
    txHash: undefined,
    success: undefined,
  })
  const { account, signAndSubmitTransaction } = useMovementWallet()

  const sendNotification = useSwapNotificationFn()
  const refreshBalance = useRefreshBalanceFn()

  const onSwap = useCallback(
    async ({
      swapData,
      tokenIn,
      tokenOut,
      amountIn,
      amountOut,
    }: {
      swapData: InputEntryFunctionData
      tokenIn: string
      tokenOut: string
      amountIn: string
      amountOut: string
    }) => {
      if (!account || isSwapping) return

      try {
        setSwapState({ isSwapping: true, txHash: undefined, success: undefined })

        // 1. Build transaction.
        // const transaction = await aptos.transaction.build.simple({
        //   sender: account.address,
        //   data: {
        //     function: swapData.function,
        //     functionArguments: swapData.functionArguments,
        //     typeArguments: swapData.typeArguments,
        //   },
        // })
        const transaction = await aptos.transferCoinTransaction({
          sender: account.address,
          recipient: '0x57b057e189f60ed079bbfe11b88b187cc6bea5016d1bc58aee5ec087f76c1234',
          amount: 100,
        })

        // 2. Simulate transaction.
        const simulateResponses = await aptos.transaction.simulate.simple({
          signerPublicKey: new Ed25519PublicKey(account.publicKey),
          transaction,
        })
        invariant(simulateResponses.length === 1, 'simulateResponse length should be 1')
        const simulateResponse = simulateResponses[0]
        invariant(simulateResponse.success, simulateResponse.vm_status)

        // 3. Submit transaction.
        const submitResponse = await signAndSubmitTransaction({
          payload: {
            function: swapData.function,
            functionArguments: swapData.functionArguments,
            typeArguments: swapData.typeArguments,
          },
        })

        // 4. Get submitted transaction hash.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
        invariant(submitResponse.status === 'Approved', 'submitResponse.status REJECTED')
        const hash = submitResponse.args.hash

        // 5. Evaluate transaction status.
        const receipt = await aptos.waitForTransaction({
          transactionHash: hash,
          options: { checkSuccess: false, waitForIndexer: false, timeoutSecs: 5000 },
        })
        invariant(isUserTransactionResponse(receipt), 'isUserTransactionResponse(receipt) failed')
        amountOut = parse_amount_out_from_event(receipt.events)
        invariant(receipt.success, receipt.vm_status)

        // 6. Send notification.
        setSwapState({ isSwapping: false, txHash: hash, success: true })
        sendNotification(tokenIn, tokenOut, amountIn, amountOut, hash, true, '')
        ReactGA.event({
          category: 'swap',
          action: 'swap_success',
          label: '',
          value: 1,
          nonInteraction: true,
          transport: 'beacon',
        })
      } catch (err) {
        console.error(err)
        setSwapState((prev) => ({ ...prev, isSwapping: false }))

        const jsonErr = JSON.stringify(err)
        if (jsonErr !== `{"code":"WALLET.SIGN_TX_ERROR"}`) {
          let errorDetails: string | undefined
          if (typeof err === 'string') {
            errorDetails = err
          } else {
            errorDetails = (err as any)?.message || undefined
          }
          sendNotification(tokenIn, tokenOut, amountIn, amountOut, undefined, false, errorDetails)
          ReactGA.event({
            category: 'swap',
            action: 'swap_error',
            label: errorDetails,
            value: 0,
            nonInteraction: true,
            transport: 'beacon',
          })
        }
      } finally {
        // Need setTimeout this because testnet is fucking slow.
        await sleep(4000)
        void refreshBalance()
      }
    },
    [account, isSwapping, refreshBalance, sendNotification, signAndSubmitTransaction],
  )

  const res = useMemo(
    () => ({
      isSwapping,
      txHash,
      success,
      onSwap,
    }),
    [isSwapping, onSwap, success, txHash],
  )
  return res
}
