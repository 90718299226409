import { Asset } from '../constants/asset'

export const getTokenAddress = (token: Asset) => (token.faAddress === '0xa' ? token.coinType : token.faAddress)

export const getShortAddress = (address: string, options?: { start?: number; end?: number }) => {
  const { start = 7, end = 5 } = options || {}

  if (!address) {
    return ''
  }

  return address.substr(0, start) + '...' + address.substr(address.length - end, address.length)
}
