import { Button } from '@nextui-org/react'
import { useState } from 'react'
import { ArrowInCircle } from './Icons.tsx'
import { Subtitle3 } from './Typography.tsx'

export default function Announcement() {
  const items = [
    // eslint-disable-next-line react/jsx-key
    <AnnouncementItemPhising />,
  ]
  const [index, setIndex] = useState(0)

  return (
    <div className="relative flex w-full items-center justify-center bg-[#433B2880] py-4 md:px-2">
      {items.map((value, idx) => (
        <div key={'announcement-' + idx} className={idx != index ? 'hidden' : ''}>
          {value}
        </div>
      ))}
      {items.length > 1 && (
        <Button
          onClick={() => {
            setIndex((prevState) => (prevState >= 1 ? prevState - 1 : items.length - 1))
          }}
          isIconOnly
          className={'bg-transparent'}
        >
          <ArrowInCircle size={20} />
        </Button>
      )}
    </div>
  )
}

// function AnnouncementItem() {
//   return (
//     <div className="flex items-center text-white">
//       <Subtitle3>
//         <span className="text-primary">Announcement</span>: Mosaic has been deployed on Porto testnet -&nbsp;
//         <span
//           className="text-primary hover:cursor-pointer"
//           onClick={() => window.open('https://x.com/mosaicagg/status/1851204608888357043')}
//         >
//           {' '}
//           Details
//         </span>
//         . Click{' '}
//         <span
//           className="text-primary hover:cursor-pointer"
//           onClick={() => window.open('https://mizu.testnet.porto.movementnetwork.xyz/')}
//         >
//           Here
//         </span>{' '}
//         for faucet
//       </Subtitle3>
//     </div>
//   )
// }
function AnnouncementItemPhising() {
  return (
    <div className="flex items-center text-white">
      <Subtitle3>
        <span className="text-primary">PHISHING WARNING:</span> please make sure you&apos;re visiting
        <span className="text-primary"> https://app.mosaic.ag</span> - check the URL carefully.
      </Subtitle3>
    </div>
  )
}
