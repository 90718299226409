import { createContext, ReactNode, useCallback, useContext, useState } from 'react'
import { useDisclosure } from '@nextui-org/react'
import { MODAL_LIST } from '../components/modals/constant.ts'

const ModalContext = createContext({
  globalModal: MODAL_LIST.CONNECT_WALLET,
  isModalOpen: false,
  onOpenModal: (_modal: MODAL_LIST) => {},
  onCloseModal: () => {},
  onOpenChangeModal: () => {},
})

export function useModal() {
  return useContext(ModalContext)
}

export function ModalProvider({ children }: { children: ReactNode }) {
  const [globalModal, setGlobalModal] = useState(MODAL_LIST.CONNECT_WALLET)
  const { isOpen, onOpen: _onOpen, onClose, onOpenChange } = useDisclosure()

  const onOpen = useCallback(
    (modal: MODAL_LIST) => {
      onClose()
      setGlobalModal(modal)
      _onOpen()
    },
    [_onOpen, onClose],
  )

  return (
    <ModalContext.Provider
      value={{
        globalModal,
        isModalOpen: isOpen,
        onOpenModal: onOpen,
        onCloseModal: onClose,
        onOpenChangeModal: onOpenChange,
      }}
    >
      {children}
    </ModalContext.Provider>
  )
}
