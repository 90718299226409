import { PartialRecord } from '../types.ts'

export interface Asset {
  id: string
  decimals: number
  name: string
  symbol: string
  whitelisted: boolean
  logoUrl?: string
  type: 'coin' | 'fungibleAsset'
  coinType: string
  faAddress: string
}

export const MOVE: Asset = {
  id: '0xa',
  faAddress: '0xa',
  coinType: '0x1::aptos_coin::AptosCoin',
  type: 'coin',
  symbol: 'MOVE',
  name: 'Movement Coin',
  decimals: 8,
  whitelisted: true,
  logoUrl: 'https://raw.githubusercontent.com/razorlabsorg/chainlist/main/chain/aptos/asset/MOVE.png',
}

export const USDT: Asset = {
  faAddress: '0xe161897670a0ee5a0e3c79c3b894a0c46e4ba54c6d2ca32e285ab4b01eb74b66',
  id: '0xe161897670a0ee5a0e3c79c3b894a0c46e4ba54c6d2ca32e285ab4b01eb74b66',
  coinType: '0x275f508689de8756169d1ee02d889c777de1cebda3a7bbcce63ba8a27c563c6f::tokens::USDT',
  type: 'coin',
  symbol: 'USDT',
  name: 'Tether USD',
  decimals: 6,
  whitelisted: true,
  logoUrl: 'https://raw.githubusercontent.com/razorlabsorg/chainlist/main/chain/aptos/asset/USDT.png',
}

export const USDC: Asset = {
  id: '0x1e74c3312b1a7a08eb7cf61310787597ea6609d6d99ce86c0e48399144ea4ce9',
  faAddress: '0x1e74c3312b1a7a08eb7cf61310787597ea6609d6d99ce86c0e48399144ea4ce9',
  coinType: '0x275f508689de8756169d1ee02d889c777de1cebda3a7bbcce63ba8a27c563c6f::tokens::USDC',
  type: 'coin',
  symbol: 'USDC',
  name: 'USD Coin',
  decimals: 6,
  whitelisted: true,
  logoUrl: 'https://raw.githubusercontent.com/razorlabsorg/chainlist/main/chain/aptos/asset/USDC.png',
}

export const WETH: Asset = {
  id: '0xa484a866e1bfcb76e8057939d6944539070b53c511813d7b21c76cae9e6a6e26',
  faAddress: '0xa484a866e1bfcb76e8057939d6944539070b53c511813d7b21c76cae9e6a6e26',
  coinType: '0x275f508689de8756169d1ee02d889c777de1cebda3a7bbcce63ba8a27c563c6f::tokens::WETH',
  type: 'coin',
  symbol: 'WETH',
  name: 'Wrapped Ether',
  decimals: 8,
  whitelisted: true,
  logoUrl: 'https://raw.githubusercontent.com/razorlabsorg/chainlist/main/chain/aptos/asset/WETH.png',
}

export const WBTC: Asset = {
  id: '0x6e8aae037a65e90ada6715e9108fb6d585a71c72d06f3963aae94e7845f24f02',
  faAddress: '0x6e8aae037a65e90ada6715e9108fb6d585a71c72d06f3963aae94e7845f24f02',
  coinType: '0x275f508689de8756169d1ee02d889c777de1cebda3a7bbcce63ba8a27c563c6f::tokens::WBTC',
  type: 'coin',
  symbol: 'WBTC',
  name: 'Wrapped Bitcoin',
  decimals: 8,
  whitelisted: true,
  logoUrl: 'https://raw.githubusercontent.com/razorlabsorg/chainlist/main/chain/aptos/asset/WBTC.png',
}

export const DEFAULT_ASSETS: PartialRecord<string, Asset> = [MOVE, USDT, USDC, WETH, WBTC].reduce<
  PartialRecord<string, Asset>
>((rs, token) => {
  rs[token.id] = token
  return rs
}, {})
