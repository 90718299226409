import { Button, Image, Modal, ModalContent } from '@nextui-org/react'
import { useIsMd } from '../../hooks/useMedia.ts'
import { CloseIcon } from '../Icons.tsx'
import { Body4, Body5, Subtitle1 } from '../Typography.tsx'
import { Hop, pathToRoutes, PoolWithAmount, Route } from '../../utils/route.ts'
import { useAppSelector } from '../../redux/hooks'
import { useMemo } from 'react'
import { truncateValue } from '../../utils/number.ts'
import { Fraction } from '../../utils/fraction.ts'
import { Icon } from '@iconify/react'

function PoolComponent({ pools }: { pools: PoolWithAmount[] }) {
  const totalAmount = pools.reduce((acc, pool) => acc.add(pool.amount), new Fraction(0))
  return (
    <div className="z-10 w-[130px] py-3">
      <div className="flex flex-col items-center justify-center divide-y divide-borderGrey2 rounded-[8px] border-1 border-borderGrey2 bg-baseGrey1">
        {pools.map((pool) => (
          <div key={pool.pool?.name} className="flex w-full items-center justify-center gap-1 p-2 text-center">
            {pool.pool?.logoUrl && <Image src={pool.pool?.logoUrl} alt={pool.pool?.logoUrl} width={16} />}
            <Body5>{pool.pool?.name}</Body5>
            <Body5>
              {truncateValue(new Fraction(pool.amount).divide(totalAmount).multiply(100).toSignificant(4), 2)}%
            </Body5>
          </div>
        ))}
      </div>
    </div>
  )
}

function RouteComponent({ routes, layer, dstCoinType }: { routes: Route[]; layer: number; dstCoinType: string }) {
  if (!routes.length) return null

  const totalAmountIn = routes.reduce((acc, route) => acc.add(route.srcAmount), new Fraction(0))
  const getPercent = (amountIn: Fraction) =>
    truncateValue(amountIn.divide(totalAmountIn).multiply(100).toSignificant(4), 2)

  return (
    <div
      className={
        'route-parent flex flex-col border-0 border-purple-500' +
        ' ' +
        (layer === 0 ? 'route-parent-layer0' : routes.length >= 2 ? 'route-parent-layer1-route2' : '')
      }
    >
      {routes.map((route, index) => (
        <div
          key={index}
          className="route relative flex items-center justify-center bg-baseGrey1"
          style={{
            zIndex: layer,
            paddingLeft: layer === 1 && routes.length >= 2 ? '50px' : 'unset',
            gap: layer === 1 && routes.length >= 2 ? '32px' : '20px',
          }}
        >
          {route.next.length || routes.length ? (
            <Body5
              className={
                'absolute z-10 bg-baseGrey1 px-1 text-primary' + ' ' + (layer === 0 ? 'left-[20px]' : 'left-[15px]')
              }
            >
              {getPercent(new Fraction(route.srcAmount))}%
            </Body5>
          ) : null}
          <PoolComponent pools={route.pools} />
          {route.dstAsset.id !== dstCoinType && (
            <div className="z-10 flex items-center gap-1 bg-baseGrey1 px-1">
              <Image src={route.dstAsset.logoUrl} alt="" width={18} />
              <Body5>{route.dstAsset.symbol}</Body5>
            </div>
          )}
          <RouteComponent routes={route.next} layer={layer + 1} dstCoinType={dstCoinType} />
        </div>
      ))}
    </div>
  )
}

export default function ModalTradeRoute({
  isOpen,
  onClose,
  onOpenChange,
  srcCoinType,
  dstCoinType,
  readableAmountIn,
  readableAmountOut,
  rawAmountIn,
  paths,
}: {
  isOpen: boolean
  onClose: () => void
  onOpenChange: () => void
  srcCoinType: string
  dstCoinType: string
  readableAmountIn: string
  readableAmountOut: string
  rawAmountIn: string | undefined
  paths: Hop[][] | undefined
}) {
  if (!Math.random())
    console.log(isOpen, srcCoinType, dstCoinType, readableAmountIn, readableAmountOut, paths, rawAmountIn)
  const isMd = useIsMd()

  const routes = paths ? pathToRoutes(paths) : undefined

  const followingTokenData = useAppSelector((state) => state.token.followingTokenData)
  const srcAsset = useMemo(() => followingTokenData[srcCoinType], [followingTokenData, srcCoinType])
  const dstAsset = useMemo(() => followingTokenData[dstCoinType], [followingTokenData, dstCoinType])

  if (!routes) return null

  return (
    <>
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        placement="top-center"
        backdrop="blur"
        hideCloseButton
        onClose={onClose}
        size={isMd ? 'full' : undefined}
      >
        <ModalContent
          className={
            'mt-[140px] w-[860px] min-w-[860px] rounded-lg bg-baseGrey1 text-foreground dark md:mt-[unset]' +
            ' ' +
            (isMd ? 'h-fit max-h-[70vh] min-h-[200px] w-full min-w-full self-end' : '')
          }
        >
          <div className="w-[860px] min-w-[860px] overflow-auto">
            <div className="flex items-center justify-between border-b-[0.5px] border-borderGrey2 px-5 py-3">
              <div className="flex items-center gap-1">
                <Icon icon="tabler:route" fontSize={24} color="#8B8D91" />
                <Subtitle1 className="text-baseGrey">Order Routing</Subtitle1>
              </div>
              <Button isIconOnly variant="light" className="h-[20px] w-[20px] min-w-fit p-0" onPress={onClose}>
                <CloseIcon size={20} />
              </Button>
            </div>

            <div className="px-5 py-4">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <Image src={srcAsset?.logoUrl || '/images/404.svg'} alt="" width={24} />
                  <Body4>
                    {readableAmountIn} {srcAsset?.symbol || '--'}
                  </Body4>
                </div>
                <div className="flex items-center gap-2">
                  <Body4>
                    {readableAmountOut} {dstAsset?.symbol || '--'}
                  </Body4>
                  <Image src={dstAsset?.logoUrl || '/images/404.svg'} alt="" width={24} />
                </div>
              </div>
              <div className="mt-1.5 flex items-center justify-between px-[9px]">
                <div className="h-[6px] w-[6px] rounded-full bg-primary" />
                <div className="h-[6px] w-[6px] rounded-full bg-primary" />
              </div>
              <div className="px-[11px]">
                <RouteComponent routes={routes} layer={0} dstCoinType={dstCoinType} />
              </div>
            </div>
          </div>
        </ModalContent>
      </Modal>
    </>
  )
}
