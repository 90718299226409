import { PartialRecord } from '../types.ts'

export const SUPPORTED_POOLS: PartialRecord<string, { name: string; shortName: string; logoUrl: string }> = {
  anime_swap_v1: { name: 'AnimeSwap', shortName: 'Anime', logoUrl: '/images/animeswap.ico' },
  anime_swap_v2: { name: 'AnimeSwap', shortName: 'Anime', logoUrl: '/images/animeswap.ico' },
  razor_swap: { name: 'RazorSwap', shortName: 'Razor', logoUrl: '/images/razor.png' },
  liquid_swap: { name: 'LiquidSwap', shortName: 'Liquid', logoUrl: '/images/liquidswap.ico' },
  meridian_stable: { name: 'Meridian', shortName: 'Meridian', logoUrl: '/images/meridian.ico' },
  meridian_weighted: { name: 'Meridian', shortName: 'Meridian', logoUrl: '/images/meridian.ico' },
  mosaic_amm: { name: 'Mosaic', shortName: 'Mosaic', logoUrl: '/favicon.ico' },
}
