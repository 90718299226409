import { Button, Input, Spacer } from '@nextui-org/react'
import { CSSProperties, memo, RefObject, useCallback, useMemo, useState } from 'react'
import { FixedSizeList } from 'react-window'
import { useCopyToClipboard, useDebounceValue, useOnClickOutside, useWindowSize } from 'usehooks-ts'
import useFullTokens from '../hooks/useFullTokens.ts'
import { useAppSelector } from '../redux/hooks'
import { PartialRecord } from '../types.ts'
import { Fraction } from '../utils/fraction.ts'
import { divpowToFraction, mulpowToFraction } from '../utils/number.ts'
import BasicTokenInfo from './BasicTokenInfo.tsx'
import { CloseIcon, SearchIcon } from './Icons.tsx'
import { Body2, Subtitle1 } from './Typography.tsx'
import { Icon } from '@iconify/react'
import { Asset } from '../constants/asset.ts'
import useMovementWallet from '../hooks/useMovementWallet.ts'
import { useGetTokenBalance } from '../hooks/useRefreshBalanceFn.ts'
import { getTokenAddress } from '../utils/token.ts'

export interface AssetWithBalance extends Asset {
  isFollowing: boolean
  fractionalBalance?: Fraction
  fractionalBalanceUsd?: Fraction
}

function TokenItem({
  index,
  data: { items, onSelectToken, onCopy, copiedId, isCopying },
  style,
}: {
  index: number
  data: {
    items: AssetWithBalance[]
    onSelectToken: (id: string) => void
    onCopy: (id: string) => void
    copiedId: string | null
    isCopying: boolean
  }
  style: CSSProperties
}) {
  const token = useMemo(() => {
    return items[index]!
  }, [items, index])

  const isCopyingThisToken = useMemo(
    () => isCopying && copiedId === getTokenAddress(token),
    [copiedId, isCopying, token],
  )

  return (
    <Button
      variant="light"
      as="div"
      disableAnimation
      disableRipple
      className={
        'm-0 flex h-fit w-full min-w-fit cursor-pointer items-center gap-2 rounded-none p-0 px-4 font-normal data-[hover]:bg-basicBg' +
        ' ' +
        (token.isFollowing ? 'opacity-100' : 'opacity-20')
      }
      style={style}
      onPress={() => onSelectToken(token.whitelisted ? token.symbol : token.id)}
    >
      <BasicTokenInfo token={token} onCopy={onCopy} isCopying={isCopyingThisToken} />
    </Button>
  )
}

const filterFn = (token: Asset, str: string) => {
  if (token.id.toLowerCase() === str.toLowerCase() || token.coinType.toLowerCase() === str.toLowerCase()) return true
  if (token.symbol.toLowerCase().includes(str.toLowerCase())) return true
  return false
}

function TokenSelector({
  swapCardRef,
  onSelectToken,
  onBack,
}: {
  swapCardRef: RefObject<HTMLDivElement>
  onSelectToken: (id: string) => void
  onBack: () => void
}) {
  useOnClickOutside(swapCardRef, onBack)

  const { data: fullTokenData } = useFullTokens()
  const followingAssetData = useAppSelector((state) => state.token.followingTokenData)
  const followingPriceData = useAppSelector((state) => state.price.followingPriceData)
  const { account } = useMovementWallet()
  const getBalance = useGetTokenBalance()
  const followingTokenDataWithBalance = useMemo(() => {
    const res: PartialRecord<string, AssetWithBalance> = {}
    for (const assetAddress of Object.keys(followingAssetData)) {
      const tokenData = followingAssetData[assetAddress]!
      let fractionalBalance: Fraction | undefined
      const tokenBalance = getBalance(tokenData)
      if (account?.address && tokenBalance) {
        fractionalBalance = divpowToFraction(tokenBalance, tokenData.decimals)
      }
      let fractionalBalanceUsd: Fraction | undefined
      if (fractionalBalance && followingPriceData[assetAddress]) {
        const fractionalPrice = mulpowToFraction(followingPriceData[assetAddress])
        fractionalBalanceUsd = fractionalBalance.multiply(fractionalPrice)
      }

      const newItem: AssetWithBalance = {
        ...tokenData,
        fractionalBalance,
        fractionalBalanceUsd,
        isFollowing: true,
      }
      res[assetAddress] = newItem
    }
    return res
  }, [account, followingPriceData, followingAssetData, getBalance])

  const followingTokenDataWithBalanceList = useMemo(() => {
    const list = Object.values(followingTokenDataWithBalance) as AssetWithBalance[]
    list.sort((a: AssetWithBalance, b: AssetWithBalance) => {
      const x = a.fractionalBalanceUsd ?? new Fraction(0)
      const y = b.fractionalBalanceUsd ?? new Fraction(0)
      if (x.lessThan(y)) {
        return 1
      } else if (x.greaterThan(y)) {
        return -1
      }
      return a.symbol.localeCompare(b.symbol)
    })
    return list
  }, [followingTokenDataWithBalance])

  const [copiedId, copy] = useCopyToClipboard()
  const [isCopying, setIsCopying] = useState(false)
  const onCopy = useCallback(
    async (id: string) => {
      try {
        setIsCopying(true)
        await copy(id)
        await new Promise((resolve) => setTimeout(resolve, 500))
      } finally {
        setIsCopying(false)
      }
    },
    [copy],
  )

  const [_searchValue, setSearchValue] = useState('')
  const [searchValue] = useDebounceValue(_searchValue, 100)

  const renderFollowingTokenList = useMemo(() => {
    const str = searchValue.trim()
    if (!str) return followingTokenDataWithBalanceList

    const res = followingTokenDataWithBalanceList.filter((token) => {
      return filterFn(token, str)
    })
    return res
  }, [followingTokenDataWithBalanceList, searchValue])
  const renderUnfollowingTokenList = useMemo(() => {
    if (!fullTokenData) return []

    const str = searchValue.trim()
    if (!str) return []

    const fullTokenDataList = Object.values(fullTokenData) as Asset[]
    const fullTokenList: AssetWithBalance[] = fullTokenDataList
      .filter((token) => {
        return filterFn(token, str)
      })
      .filter((token) => !renderFollowingTokenList.map((token) => token.id).includes(token.id))
      .map((token) => ({
        ...token,
        id: token.id,
        name: token.name,
        symbol: token.symbol,
        decimals: token.decimals,
        whitelisted: false,
        logoUrl: undefined,
        fractionalBalance: undefined,
        fractionalBalanceUsd: undefined,
        isFollowing: false,
      }))
      .sort((a: AssetWithBalance, b: AssetWithBalance) => {
        const x = a.fractionalBalanceUsd ?? new Fraction(0)
        const y = b.fractionalBalanceUsd ?? new Fraction(0)
        if (x.lessThan(y)) {
          return 1
        } else if (x.greaterThan(y)) {
          return -1
        }
        return a.symbol.localeCompare(b.symbol)
      })
    return fullTokenList
  }, [fullTokenData, renderFollowingTokenList, searchValue])
  const renderTokenList = useMemo(
    () => [...renderFollowingTokenList, ...renderUnfollowingTokenList],
    [renderFollowingTokenList, renderUnfollowingTokenList],
  )
  const isEmpty = renderTokenList.length === 0

  const itemData = useMemo(
    () => ({ items: renderTokenList, onSelectToken, onCopy, copiedId, isCopying }),
    [copiedId, isCopying, onCopy, renderTokenList, onSelectToken],
  )

  const { height: windowHeight } = useWindowSize()
  const listHeight = useMemo(() => Math.min(500, Math.round(windowHeight / 2 / 64) * 64), [windowHeight])

  return (
    <div className="flex flex-col rounded-lg border-[0.5px] border-borderGrey2 bg-baseGrey1 p-3">
      <div className="flex items-center justify-between ">
        <Button
          variant="light"
          className="m-0 h-fit w-fit min-w-fit gap-0 p-0 data-[hover]:bg-transparent"
          disableAnimation
          disableRipple
          onPress={onBack}
        >
          <Icon icon="mdi:chevron-left" color="#8B8D91" fontSize={24} />
        </Button>
        <Subtitle1 className="text-baseGrey">Select an asset</Subtitle1>
        <Button
          variant="light"
          className="invisible m-0 h-fit w-fit min-w-fit gap-0 p-0 data-[hover]:bg-transparent"
          disableAnimation
          disableRipple
          onPress={onBack}
        >
          <Icon icon="mdi:chevron-left" color="#8B8D91" fontSize={24} />
        </Button>
      </div>

      <Spacer y={4} />

      <Input
        type="text"
        placeholder="Search by token symbol or address"
        labelPlacement="outside"
        autoComplete="off"
        autoCorrect="off"
        spellCheck={false}
        className="input-modal-select-token"
        startContent={<SearchIcon size={20} color="#8B8D91" className="min-w-[20px]" />}
        endContent={
          searchValue ? (
            <Button
              isIconOnly
              className="m-0 h-fit w-fit min-w-fit border-transparent bg-transparent p-0"
              disableRipple
              onPress={() => setSearchValue('')}
            >
              <CloseIcon size={16} color="#8B8D91" />
            </Button>
          ) : null
        }
        value={_searchValue}
        onChange={(e) => setSearchValue(e.currentTarget.value)}
      />

      <Spacer y={2} />

      {renderTokenList && (
        <div className="relative -mx-3 border-t-1 border-t-borderGrey2">
          <FixedSizeList
            height={listHeight}
            itemCount={renderTokenList.length}
            itemSize={64}
            width="100%"
            itemData={itemData}
          >
            {TokenItem}
          </FixedSizeList>
          {isEmpty && (
            <Body2 className="absolute left-1/2 top-1/4 -translate-x-1/2 -translate-y-1/2 text-baseGrey">
              No Token Found
            </Body2>
          )}
        </div>
      )}
    </div>
  )
}

const MemorizedModalSelectToken = memo(TokenSelector)
export default MemorizedModalSelectToken
