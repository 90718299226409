import { Icon } from '@iconify/react'
import { Button, Image, Skeleton, Spacer } from '@nextui-org/react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { NumericFormat } from 'react-number-format'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useDebounceValue } from 'usehooks-ts'
import { SettingIcon } from '../../components/Icons.tsx'
import CountdownSpinner from '../../components/CountdownSpinner.tsx'
import { BIP_BASE } from '../../constants'
import { SUPPORTED_POOLS } from '../../constants/pool.ts'
import useFullTokens from '../../hooks/useFullTokens.ts'
import useQuote from '../../hooks/useQuote.ts'
import useSwap from '../../hooks/useSwap.tsx'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { addTokensToFollow } from '../../redux/slices/token.ts'
import { Fraction } from '../../utils/fraction.ts'
import {
  divpowToFraction,
  escapeRegExp,
  inputRegex,
  mulpowToFraction,
  numberWithCommas,
  truncateValue,
} from '../../utils/number.ts'
import useMovementNetworkStatus from '../../hooks/useMovementNetworkStatus.ts'
import TokenSelector from '../../components/TokenSelector.tsx'
import SwapSettings from './SwapSettings.tsx'
import ModalTradeRoute from '../../components/modals/ModalTradeRoute.tsx'
import { useModal } from '../../provider/ModalProvider.tsx'
import { MODAL_LIST } from '../../components/modals/constant.ts'
import { Body0, Body2, Body4 } from '../../components/Typography.tsx'
import { ButtonBase } from '../../components/Button.tsx'
import { SwapPanel } from './constant.ts'
import { Asset, MOVE, USDC } from '../../constants/asset.ts'
import useMovementWallet from '../../hooks/useMovementWallet.ts'
import { motion, useAnimationControls } from 'framer-motion'
import Announcement from '../../components/Announcement.tsx'
import { useTokenBalance } from '../../hooks/useRefreshBalanceFn.ts'

export default function SwapPage() {
  const { globalModal, isModalOpen, onOpenModal, onCloseModal, onOpenChangeModal } = useModal()

  const { account, connected } = useMovementWallet()

  const dispatch = useAppDispatch()

  const location = useLocation()
  const navigate = useNavigate()
  const [params] = useSearchParams()

  const resetTimerFunction = useRef(() => {})
  const setResetTimerFunc = (f: () => void) => (resetTimerFunction.current = f)

  const { isNetworkStable, isLoadingNetworkStatus } = useMovementNetworkStatus()

  const [typedAmountIn, _setTypedAmountIn] = useState('1')
  const [shouldUseDebounceAmountIn, setShouldUseDebounceAmountIn] = useState(true)
  const setTypedAmountIn = useCallback((value: string, decimals = 8, shouldUseDebounce = true) => {
    setShouldUseDebounceAmountIn(shouldUseDebounce)
    if (value?.endsWith(',')) {
      value = value.slice(0, value.length - 1) + '.'
    }
    value = value.replaceAll(',', '')
    if (value === '' || inputRegex.test(escapeRegExp(value))) {
      value = truncateValue(value, decimals)
      if (value.length && value.startsWith('.')) value = '0.'
      value = numberWithCommas(value)
      _setTypedAmountIn(value)
    }
  }, [])

  const followingTokenData = useAppSelector((state) => state.token.followingTokenData)
  const tokenIn = useMemo(
    () =>
      (Object.values(followingTokenData) as Asset[]).find((token) => {
        try {
          const tokenSymbolOrAddress = location.pathname.replace('/swap/', '').split('-')[0]
          return token.symbol === tokenSymbolOrAddress || token.id === tokenSymbolOrAddress
        } catch {
          return false
        }
      })?.id || MOVE.id,
    [followingTokenData, location.pathname],
  )
  const tokenOut = useMemo(
    () =>
      (Object.values(followingTokenData) as Asset[]).find((token) => {
        try {
          const tokenSymbolOrAddress = location.pathname.replace('/swap/', '').split('-')[1]
          return token.symbol === tokenSymbolOrAddress || token.id === tokenSymbolOrAddress
        } catch {
          return false
        }
      })?.id || USDC.id,
    [followingTokenData, location.pathname],
  )

  const tokenInInfo: Asset | undefined = useMemo(() => followingTokenData[tokenIn], [followingTokenData, tokenIn])
  const tokenOutInfo: Asset | undefined = useMemo(() => followingTokenData[tokenOut], [followingTokenData, tokenOut])

  const { data: fullTokenData } = useFullTokens()
  useEffect(() => {
    const pair = location.pathname.replace('/swap/', '')
    try {
      const tokenInSymbolOrAddress = pair.split('-')[0]
      const tokenOutSymbolOrAddress = pair.split('-')[1]
      if (!tokenInSymbolOrAddress || !tokenOutSymbolOrAddress) throw new Error(`invalid pair = ${pair}`)

      const followingTokenDataList = Object.values(followingTokenData) as Asset[]

      if (!fullTokenData || Object.values(fullTokenData).length === 0) return
      const fullTokenDataList = Object.values(fullTokenData) as Asset[]

      const findFn = (symbolOrAddress: string) =>
        fullTokenDataList.find((token) => token.id === symbolOrAddress) ||
        followingTokenDataList.find((token) => token.symbol === symbolOrAddress || token.id === symbolOrAddress)

      const newTokenIn = findFn(tokenInSymbolOrAddress)
      const newTokenOut = findFn(tokenOutSymbolOrAddress)

      if (!newTokenIn) throw new Error(`cannot find tokenIn = ${tokenInSymbolOrAddress}`)
      if (!newTokenOut) throw new Error(`cannot find tokenOut = ${tokenOutSymbolOrAddress}`)
      dispatch(addTokensToFollow([newTokenIn.id, newTokenOut.id]))
    } catch (err) {
      pair !== '/swap' && console.error(err)
      navigate(`/swap/MOVE-USDC?${params.toString()}`, { replace: true })
    }
  }, [dispatch, followingTokenData, fullTokenData, location.pathname, navigate, params])

  const _setTokenIn = useCallback(
    (symbolOrAddress: string) => {
      const pair = location.pathname.replace('/swap/', '')
      try {
        const tokenInSymbolOrAddress = pair.split('-')[0]
        const tokenOutSymbolOrAddress = pair.split('-')[1]
        if (!tokenInSymbolOrAddress || !tokenOutSymbolOrAddress) throw new Error(`invalid pair = ${pair}`)
        navigate(`/swap/${symbolOrAddress}-${tokenOutSymbolOrAddress}?${params.toString()}`, { replace: true })
      } catch (err) {
        pair !== '/swap' && console.error(err)
        navigate(`/swap/MOVE-USDC?${params.toString()}`, { replace: true })
      }
    },
    [location.pathname, navigate, params],
  )
  const _setTokenOut = useCallback(
    (symbolOrAddress: string) => {
      const pair = location.pathname.replace('/swap/', '')
      try {
        const tokenInSymbolOrAddress = pair.split('-')[0]
        const tokenOutSymbolOrAddress = pair.split('-')[1]
        if (!tokenInSymbolOrAddress || !tokenOutSymbolOrAddress) throw new Error(`invalid pair = ${pair}`)
        navigate(`/swap/${tokenInSymbolOrAddress}-${symbolOrAddress}?${params.toString()}`, { replace: true })
      } catch (err) {
        pair !== '/swap' && console.error(err)
        navigate(`/swap/MOVE-USDC?${params.toString()}`, { replace: true })
      }
    },
    [location.pathname, navigate, params],
  )

  const tokenInDecimals = tokenInInfo ? tokenInInfo.decimals : undefined
  const tokenOutDecimals = tokenOutInfo ? tokenOutInfo.decimals : undefined

  const followingPriceData = useAppSelector((state) => state.price.followingPriceData)
  const fractionalPriceTokenIn = useMemo(
    () => (followingPriceData[tokenIn] ? mulpowToFraction(followingPriceData[tokenIn]) : undefined),
    [followingPriceData, tokenIn],
  )
  const fractionalPriceTokenOut = useMemo(
    () => (followingPriceData[tokenOut] ? mulpowToFraction(followingPriceData[tokenOut]) : undefined),
    [followingPriceData, tokenOut],
  )

  const balanceTokenIn = useTokenBalance(tokenInInfo)
  const fractionalBalanceTokenIn = useMemo(
    () =>
      balanceTokenIn && tokenInDecimals !== undefined ? divpowToFraction(balanceTokenIn, tokenInDecimals) : undefined,
    [balanceTokenIn, tokenInDecimals],
  )
  const balanceTokenOut = useTokenBalance(tokenOutInfo)

  const fractionalBalanceTokenOut = useMemo(
    () =>
      balanceTokenOut && tokenOutDecimals !== undefined
        ? divpowToFraction(balanceTokenOut, tokenOutDecimals)
        : undefined,
    [balanceTokenOut, tokenOutDecimals],
  )

  const _fractionalAmountIn = useMemo(
    () =>
      typedAmountIn && tokenInDecimals !== undefined
        ? mulpowToFraction(typedAmountIn.replaceAll(',', ''), tokenInDecimals)
        : undefined,
    [tokenInDecimals, typedAmountIn],
  )
  const [fractionalAmountIn] = useDebounceValue(_fractionalAmountIn, shouldUseDebounceAmountIn ? 100 : 0)

  const [source, setSource] = useState('')

  const slippageBps = useAppSelector((state) => state.user.slippageBps)
  // const isHighSlippage = slippageBps >= 500

  const { txHash, isSwapping, onSwap: _onSwap, success: isSwapSuccess } = useSwap()

  const {
    dstAmount,
    isValidating: isValidatingQuote,
    paths,
    swapData,
    reFetch,
  } = useQuote(
    isSwapping,
    account?.address,
    tokenIn,
    tokenOut,
    fractionalAmountIn?.numerator?.toString(),
    slippageBps,
    source,
  )
  const fractionalAmountOut = useMemo(
    () => (dstAmount && tokenOutDecimals ? divpowToFraction(dstAmount, tokenOutDecimals) : undefined),
    [dstAmount, tokenOutDecimals],
  )

  const readableAmountOut =
    fractionalAmountOut && tokenOutDecimals !== undefined
      ? numberWithCommas(truncateValue(fractionalAmountOut.toFixed(18), tokenOutDecimals))
      : ''

  const fractionalAmountInUsd = useMemo(
    () =>
      fractionalAmountIn && fractionalPriceTokenIn ? fractionalAmountIn.multiply(fractionalPriceTokenIn) : undefined,
    [fractionalAmountIn, fractionalPriceTokenIn],
  )
  const fractionalAmountOutUsd = useMemo(
    () =>
      fractionalAmountOut && fractionalPriceTokenOut
        ? fractionalAmountOut.multiply(fractionalPriceTokenOut)
        : undefined,
    [fractionalAmountOut, fractionalPriceTokenOut],
  )

  const rate = useMemo(
    () => (fractionalAmountIn && fractionalAmountOut ? fractionalAmountOut.divide(fractionalAmountIn) : undefined),
    [fractionalAmountIn, fractionalAmountOut],
  )
  const priceImpact = useMemo(() => {
    let res =
      fractionalAmountInUsd && fractionalAmountOutUsd
        ? fractionalAmountInUsd.subtract(fractionalAmountOutUsd).divide(fractionalAmountInUsd).multiply(100)
        : undefined
    if (res?.lessThan(0)) {
      res = new Fraction(0)
    }
    return res
  }, [fractionalAmountInUsd, fractionalAmountOutUsd])
  const isPriceImpactVeryHigh = useMemo(() => Boolean(priceImpact?.greaterThan(10)), [priceImpact])
  const isPriceImpactHigh = useMemo(() => Boolean(priceImpact?.greaterThan(5)), [priceImpact])

  const minimumReceived = useMemo(() => {
    if (!fractionalAmountOut) return undefined
    // If any tokens have more than 8 decimals, this assignment will break. I assume 8 is the max decimals in aptos chain? Never mind, I will use 18.
    const str = fractionalAmountOut
      .multiply(BIP_BASE - slippageBps)
      .divide(BIP_BASE)
      .toFixed(18)
    const res = mulpowToFraction(str, tokenOutDecimals) // To cut redundant decimals.
    return res
  }, [fractionalAmountOut, slippageBps, tokenOutDecimals])

  const [isInvert, setIsInvert] = useState(false)

  const fractionalFeeAmount = useMemo(
    () => (tokenIn === MOVE.id ? new Fraction(2, 1000) : new Fraction(0, 1)),
    [tokenIn],
  )
  const isSufficientBalance =
    fractionalBalanceTokenIn && fractionalAmountIn
      ? fractionalBalanceTokenIn.subtract(fractionalFeeAmount).equalTo(fractionalAmountIn) ||
        fractionalBalanceTokenIn.subtract(fractionalFeeAmount).greaterThan(fractionalAmountIn)
      : undefined

  const onSetPercentAmountIn = (percent: number) => {
    if (fractionalBalanceTokenIn && fractionalFeeAmount) {
      let newTypedAmountIn = fractionalBalanceTokenIn.multiply(percent).divide(100)
      if (fractionalBalanceTokenIn.subtract(fractionalFeeAmount).lessThan(newTypedAmountIn)) {
        newTypedAmountIn = newTypedAmountIn.subtract(fractionalFeeAmount)
      }
      if (newTypedAmountIn.greaterThan(0)) {
        const newTypedAmountInStr = newTypedAmountIn.toFixed(18)
        setTypedAmountIn(newTypedAmountInStr, tokenInDecimals, false)
      } else {
        setTypedAmountIn('', tokenInDecimals, false)
      }
    } else {
      setTypedAmountIn('', tokenInDecimals, false)
    }
  }

  const swapButton = useMemo(() => {
    if (isSwapping) return { isDisabled: true, text: 'Swapping...' }
    if (isLoadingNetworkStatus) return { isDisabled: true, text: 'Checking network status...' }
    if (!isNetworkStable) return { isDisabled: true, text: 'Network is not stable now' }
    if (!fractionalAmountIn) return { isDisabled: true, text: 'Enter an amount' }
    if (!isSufficientBalance) return { isDisabled: true, text: 'Insufficient balance' }
    if (isValidatingQuote) return { isDisabled: true, text: 'Getting quote...' }
    if (!fractionalAmountOut) return { isDisabled: true, text: 'Not found route' }
    return { isDisabled: false, text: 'Swap' }
  }, [
    isLoadingNetworkStatus,
    isNetworkStable,
    fractionalAmountIn,
    isSufficientBalance,
    isValidatingQuote,
    fractionalAmountOut,
    isSwapping,
  ])

  const [tokenInLogoSrc, setTokenInLogoSrc] = useState(tokenInInfo?.logoUrl || '/images/404.svg')
  const [tokenOutLogoSrc, setTokenOutLogoSrc] = useState(tokenOutInfo?.logoUrl || '/images/404.svg')
  useEffect(() => {
    setTokenInLogoSrc(tokenInInfo?.logoUrl || '/images/404.svg')
    setTokenOutLogoSrc(tokenOutInfo?.logoUrl || '/images/404.svg')
  }, [tokenInInfo?.logoUrl, tokenOutInfo?.logoUrl])

  const switchToken = useCallback(() => {
    if (fractionalAmountOut && tokenOutDecimals !== undefined) {
      setTypedAmountIn(truncateValue(fractionalAmountOut.toFixed(18), tokenOutDecimals), tokenOutDecimals, false)
    } else {
      setTypedAmountIn('')
    }
    const pair = location.pathname.replace('/swap/', '')
    try {
      const tokenInSymbolOrAddress = pair.split('-')[0]
      const tokenOutSymbolOrAddress = pair.split('-')[1]
      if (!tokenInSymbolOrAddress || !tokenOutSymbolOrAddress) throw new Error(`invalid pair = ${pair}`)
      navigate(`/swap/${tokenOutSymbolOrAddress}-${tokenInSymbolOrAddress}?${params.toString()}`, {
        replace: true,
      })
    } catch (err) {
      pair !== '/swap' && console.error(err)
      navigate(`/swap/MOVE-USDC?${params.toString()}`, { replace: true })
    }
  }, [fractionalAmountOut, location.pathname, navigate, params, setTypedAmountIn, tokenOutDecimals])

  const [activePanel, setActivePanel] = useState(SwapPanel.Swap)
  const setTokenIn = useCallback(
    (symbolOrAddress: string) => {
      if (tokenOut === symbolOrAddress || (tokenOutInfo && tokenOutInfo.symbol === symbolOrAddress)) {
        switchToken()
      } else {
        _setTokenIn(symbolOrAddress)
      }
      setActivePanel(SwapPanel.Swap)
    },
    [_setTokenIn, switchToken, tokenOut, tokenOutInfo],
  )
  const setTokenOut = useCallback(
    (symbolOrAddress: string) => {
      if (tokenIn === symbolOrAddress || (tokenInInfo && tokenInInfo.symbol === symbolOrAddress)) {
        switchToken()
      } else {
        _setTokenOut(symbolOrAddress)
      }
      setActivePanel(SwapPanel.Swap)
    },
    [_setTokenOut, switchToken, tokenIn, tokenInInfo],
  )

  const swapCardRef = useRef<HTMLDivElement>(null)

  const onSwap = () => {
    if (fractionalAmountIn && fractionalAmountOut && swapData) {
      void _onSwap({
        tokenIn,
        tokenOut,
        amountIn: fractionalAmountIn.numerator.toString(),
        amountOut: fractionalAmountOut.numerator.toString(),
        swapData,
      })
    }
  }

  const isDebug = useMemo(() => params.get('debug') === 'true', [params])

  useEffect(() => {
    resetTimerFunction.current()
  }, [fractionalAmountIn, tokenIn, tokenOut, isValidatingQuote])

  const [isShowTradeDetails, setShowTradeDetails] = useState(false)

  const controls = useAnimationControls()
  useEffect(() => {
    if (isShowTradeDetails) {
      controls.mount()
      void controls.start({ height: 'fit-content' })
    } else {
      void controls.start({ height: '0' })
    }
  }, [controls, isShowTradeDetails])

  return (
    <>
      <main className="isolate flex flex-col">
        {/*<div className="fixed top-0 h-full w-screen">*/}
        {/*  <Image*/}
        {/*    className="fixed bottom-[-7vw] left-[-7vw] h-[30vw] max-h-[479.67px] xl:h-[25vw]"*/}
        {/*    src="/images/background_object_1.svg"*/}
        {/*  />*/}
        {/*  <Image*/}
        {/*    className="fixed right-[-7vw] top-[73px] h-[30vw] max-h-[479.67px] xl:h-[25vw] lg:bottom-0 lg:top-[unset]"*/}
        {/*    src="/images/background_object_2.svg"*/}
        {/*  />*/}
        {/*</div>*/}
        <div className="fixed top-0 h-dvh w-screen">
          <Image className="h-dvh w-screen" src="/images/background.avif" />
        </div>
        {isDebug && (
          <div className="absolute left-0 top-1/2 z-20 w-[250px] -translate-y-1/2 border-1 border-red-500 p-4 text-white">
            <div>💡 Press cmd and click to multiple select source.</div>
            <a
              href={`https://explorer.movementnetwork.xyz/txn/${txHash}?network=porto+testnet`}
              target="_blank"
              rel="noreferrer"
              className="break-all"
            >
              tx_version: {txHash ? `https://explorer.movementnetwork.xyz/txn/${txHash}?network=porto+testnet` : '--'}
            </a>
            <div>tx_success: {isSwapSuccess === undefined ? '--' : isSwapSuccess ? 'true' : 'false'}</div>
            <div>
              <select
                className="h-[50vh] border-1 border-red-500"
                onChange={(e) => {
                  setSource(
                    [...e.currentTarget.options]
                      .filter((op) => op.selected)
                      .map((op) => op.value)
                      .join(','),
                  )
                }}
                multiple
              >
                {Object.keys(SUPPORTED_POOLS).map((source) => (
                  <option key={source} value={source}>
                    {source}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}
        <div className="mt-[20px] w-full">
          <Announcement />
        </div>
        <div className="z-[1] w-full p-4 pt-[40px]">
          <div className="mx-auto flex max-w-[450px] flex-col" ref={swapCardRef}>
            <div className="flex justify-end">
              <Button
                isIconOnly
                className={'h-[32px] w-[32px] min-w-min bg-transparent'}
                disableAnimation
                onPress={async () => {
                  if (!isValidatingQuote) await reFetch()
                }}
              >
                <CountdownSpinner
                  timeInSeconds={10}
                  onFinishCountdown={reFetch}
                  setResetTimerFunc={setResetTimerFunc}
                  isLoading={isValidatingQuote || isSwapping}
                  size={25}
                />
              </Button>
              <Button
                isIconOnly
                className="m-0 h-[32px] w-[32px] min-w-min bg-transparent p-0"
                onPress={() => setActivePanel(SwapPanel.SwapSettings)}
                disableAnimation
              >
                <SettingIcon size={24} color={'#8B8D91'} />
              </Button>
            </div>

            <Spacer y={2} />

            {activePanel === SwapPanel.Swap && (
              <>
                <div className="relative flex flex-col gap-1">
                  {/* INPUT */}
                  <>
                    <div className="flex flex-col gap-2 rounded-lg border-[0.5px] border-borderGrey2 bg-baseGrey1 p-3">
                      <div className="flex items-center justify-between">
                        <Body4 className="text-baseGrey">You&apos;re Paying</Body4>
                        {connected && (
                          <Button
                            className="flex h-fit w-fit min-w-fit items-center gap-1 bg-transparent p-0"
                            disableAnimation
                            disableRipple
                            onClick={() => onSetPercentAmountIn(100)}
                          >
                            <Icon icon="mdi:wallet" color="#8B8D91" fontSize={14.08} />
                            <Body4 className="text-baseGrey">
                              {fractionalBalanceTokenIn
                                ? numberWithCommas(fractionalBalanceTokenIn.toSignificant(6))
                                : '0'}
                            </Body4>
                          </Button>
                        )}
                      </div>
                      <div className="flex items-center justify-between gap-3">
                        <NumericFormat
                          decimalSeparator="."
                          allowedDecimalSeparators={[',']}
                          thousandSeparator
                          inputMode="decimal"
                          autoComplete="off"
                          autoCorrect="off"
                          type="text"
                          placeholder="0.00"
                          minLength={1}
                          maxLength={30}
                          spellCheck="false"
                          className="w-full bg-transparent text-[36px] font-medium leading-[42px] text-white outline-none placeholder:text-baseGrey"
                          pattern="^[0-9]*[.,]?[0-9]*$"
                          value={typedAmountIn}
                          allowNegative={false}
                          onChange={(e) => setTypedAmountIn(e.currentTarget.value, tokenInDecimals)}
                        />
                        <Button
                          className="flex h-[35px] w-fit min-w-fit items-center gap-0 border-1 border-borderGrey2 bg-basicBg p-0 transition"
                          disableAnimation
                          disableRipple
                          onPress={() => setActivePanel(SwapPanel.SelectTokenIn)}
                        >
                          <div className="ml-1.5">
                            <Image
                              width={20}
                              height={20}
                              className="min-h-[20px] min-w-[20px]"
                              src={tokenInLogoSrc}
                              onError={() => setTokenInLogoSrc('/images/404.svg')}
                            />
                          </div>
                          <Body0 className="ml-1.5 whitespace-nowrap text-baseGrey">
                            {tokenInInfo?.symbol ?? '--'}
                          </Body0>
                          <Icon icon="mdi:chevron-down" color="#8B8D91" fontSize={24} />
                        </Button>
                      </div>
                      <div className="flex items-center justify-between gap-3">
                        <Body4 className="text-baseGrey">
                          {fractionalAmountInUsd
                            ? '~$' + numberWithCommas(fractionalAmountInUsd.toSignificant(6), false, 2)
                            : '--'}
                        </Body4>
                      </div>
                    </div>
                  </>
                  {/* SWITCH TOKEN */}
                  <div className="absolute left-1/2 top-1/2 z-[1] -translate-x-1/2 -translate-y-1/2">
                    <Button
                      isIconOnly
                      className="h-[36px] min-h-[36px] w-[36px] min-w-[36px] rounded-lg border-[0.5px] border-borderGrey2 bg-basicBg"
                      onPress={switchToken}
                    >
                      <Icon icon="mdi:arrow-down" color="#8B8D91" fontSize={22} />
                    </Button>
                  </div>
                  {/* OUTPUT */}
                  <>
                    <div className="flex flex-col gap-2 rounded-lg border-[0.5px] border-borderGrey2 bg-baseGrey1 p-3">
                      <div className="flex items-center justify-between">
                        <Body4 className="text-baseGrey">To Receive</Body4>
                        {connected && (
                          <Button
                            className="flex h-fit w-fit min-w-fit items-center gap-1 bg-transparent p-0"
                            disableAnimation
                            disableRipple
                          >
                            <Icon icon="mdi:wallet" color="#8B8D91" fontSize={14.08} />
                            <Body4 className="text-baseGrey">
                              {fractionalBalanceTokenOut
                                ? numberWithCommas(fractionalBalanceTokenOut.toSignificant(6))
                                : '0'}
                            </Body4>
                          </Button>
                        )}
                      </div>
                      <div className="flex items-center justify-between gap-3">
                        <NumericFormat
                          decimalSeparator="."
                          allowedDecimalSeparators={[',']}
                          thousandSeparator
                          inputMode="decimal"
                          autoComplete="off"
                          autoCorrect="off"
                          type="text"
                          placeholder="0.00"
                          minLength={1}
                          maxLength={30}
                          spellCheck="false"
                          className="w-full bg-transparent text-[36px] font-medium leading-[42px] text-white outline-none placeholder:text-baseGrey"
                          style={{ color: '#8B8D91', WebkitTextFillColor: '#8B8D91' }}
                          pattern="^[0-9]*[.,]?[0-9]*$"
                          disabled
                          data-tooltip-id="tooltip-input-amount-out"
                          value={readableAmountOut}
                        />
                        <Button
                          className="flex h-[35px] w-fit min-w-fit items-center gap-0 border-1 border-borderGrey2 bg-basicBg p-0 transition"
                          disableAnimation
                          disableRipple
                          onPress={() => setActivePanel(SwapPanel.SelectTokenOut)}
                        >
                          <div className="ml-1.5">
                            <Image
                              width={20}
                              height={20}
                              className="min-h-[20px] min-w-[20px]"
                              src={tokenOutLogoSrc}
                              onError={() => setTokenOutLogoSrc('/images/404.svg')}
                            />
                          </div>
                          <Body0 className="ml-1.5 whitespace-nowrap text-baseGrey">
                            {tokenOutInfo?.symbol ?? '--'}
                          </Body0>
                          <Icon icon="mdi:chevron-down" color="#8B8D91" fontSize={24} />
                        </Button>
                      </div>
                      <div className="flex items-center justify-between gap-3">
                        <div className="flex items-center justify-between gap-1">
                          <Body4 className="text-baseGrey">
                            {fractionalAmountOutUsd
                              ? '~$' + numberWithCommas(fractionalAmountOutUsd.toSignificant(6), false, 2)
                              : '--'}
                          </Body4>
                          {priceImpact && priceImpact.greaterThan(new Fraction(1, 100)) && (
                            <Body4
                              data-tooltip-id="tooltip-price-impact"
                              className={
                                isPriceImpactVeryHigh
                                  ? 'text-error'
                                  : isPriceImpactHigh
                                    ? 'text-primary'
                                    : 'text-baseGrey'
                              }
                            >
                              ({`-${truncateValue(priceImpact.toSignificant(4), 2)}%`})
                            </Body4>
                          )}
                        </div>
                        {fractionalAmountIn && fractionalAmountOut && priceImpact === undefined ? (
                          <div className="flex items-center gap-1">
                            <Icon icon="ph:warning" fontSize={14} color="#F44646" />
                            <Body4 className="text-error">Can&apos;t calculate price impact</Body4>
                          </div>
                        ) : isPriceImpactVeryHigh ? (
                          <div className="flex items-center gap-1">
                            <Icon icon="ph:warning" fontSize={14} color="#F44646" />
                            <Body4 className="text-error">Price impact is very high</Body4>
                          </div>
                        ) : isPriceImpactHigh ? (
                          <div className="flex items-center gap-1">
                            <Icon icon="ph:warning" fontSize={14} color="#FF9901" />
                            <Body4 className="text-primary">Price impact is high</Body4>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </>
                </div>

                <Spacer y={1} />

                {connected ? (
                  <ButtonBase
                    v={
                      (fractionalAmountIn && fractionalAmountOut && !priceImpact) || isPriceImpactVeryHigh
                        ? 'error'
                        : 'primary'
                    }
                    className="h-[48px] min-h-[48px] gap-0 rounded-[8px]"
                    onPress={onSwap}
                    isDisabled={swapButton.isDisabled}
                  >
                    <Body2>{swapButton.text}</Body2>
                  </ButtonBase>
                ) : (
                  <ButtonBase
                    v="primary"
                    className="h-[48px] min-h-[48px] gap-0 rounded-[8px]"
                    onPress={() => onOpenModal(MODAL_LIST.CONNECT_WALLET)}
                  >
                    <Body2>Connect Wallet</Body2>
                  </ButtonBase>
                )}

                <Spacer y={3} />

                {fractionalAmountIn && fractionalAmountOut && (
                  <>
                    <div
                      onClick={() => setShowTradeDetails((prev) => !prev)}
                      className="flex flex-col overflow-hidden rounded-lg border-[0.5px] border-borderGrey2 bg-baseGrey1"
                    >
                      <div className="flex justify-between">
                        <div className="ml-3 flex items-center gap-2 overflow-hidden">
                          {isValidatingQuote ? (
                            <>
                              <div className="flex h-[14.08px] w-[150px] items-center">
                                <Skeleton className="h-[14.08px] w-full rounded bg-baseGrey" />
                              </div>
                            </>
                          ) : isInvert ? (
                            <Button
                              onPress={() => setIsInvert((prev) => !prev)}
                              variant="light"
                              className="m-0 h-fit gap-0 p-0 data-[hover]:bg-transparent"
                              disableAnimation
                              disableRipple
                            >
                              <Body4 className="overflow-hidden text-ellipsis whitespace-nowrap text-white">
                                {rate
                                  ? `1 ${tokenOutInfo?.symbol ?? '--'} = ${numberWithCommas(rate.invert().toSignificant(6))} ${tokenInInfo?.symbol ?? '--'}`
                                  : '--'}
                              </Body4>
                            </Button>
                          ) : (
                            <Button
                              onPress={() => setIsInvert((prev) => !prev)}
                              variant="light"
                              className="m-0 h-fit gap-0 p-0 data-[hover]:bg-transparent"
                              disableAnimation
                              disableRipple
                            >
                              <Body4 className="overflow-hidden text-ellipsis whitespace-nowrap text-white">
                                {rate
                                  ? `1 ${tokenInInfo?.symbol ?? '--'} = ${numberWithCommas(rate.toSignificant(6))} ${tokenOutInfo?.symbol ?? '--'}`
                                  : '--'}
                              </Body4>
                            </Button>
                          )}
                        </div>
                        <Button
                          variant="light"
                          className="m-0 flex h-[39.08px] w-[50px] min-w-fit justify-end gap-0 py-0 pl-0 pr-3 text-right data-[hover]:bg-transparent"
                          disableAnimation
                          disableRipple
                          onPress={() => setShowTradeDetails((prev) => !prev)}
                        >
                          <motion.div
                            initial={{ rotate: 0 }}
                            animate={{ rotate: isShowTradeDetails ? '180deg' : 0 }}
                            className="min-w-[20px]"
                          >
                            <Icon icon="mdi:chevron-down" color="#8B8D91" fontSize={20} className="-mr-1" />
                          </motion.div>
                        </Button>
                      </div>
                      <motion.div
                        initial={{ height: 0, opacity: 0, display: 'none' }}
                        animate={{
                          height: isShowTradeDetails ? 'fit-content' : 0,
                          opacity: isShowTradeDetails ? 1 : 0,
                          display: 'flex',
                          marginBottom: isShowTradeDetails ? '12px' : '0',
                        }}
                        transition={{ duration: 0.25 }}
                        className="mx-3 flex flex-col gap-2 overflow-hidden"
                      >
                        <div className="mt-1 flex items-center justify-between py-1">
                          <div className="flex items-center gap-1">
                            <Body4 className="text-baseGrey">Price Impact</Body4>
                          </div>
                          {isValidatingQuote ? (
                            <>
                              <div className="flex h-[14.08px] w-[100px] items-center">
                                <Skeleton className="h-[14.08px] w-full rounded bg-baseGrey" />
                              </div>
                            </>
                          ) : (
                            <Body4 className="text-white">
                              {priceImpact
                                ? priceImpact.equalTo(0)
                                  ? '0%'
                                  : `-${truncateValue(priceImpact.toSignificant(4), 2)}%`
                                : '--'}
                            </Body4>
                          )}
                        </div>
                        <div className="flex items-center justify-between py-1">
                          <div className="flex items-center gap-1">
                            <Body4 className="text-baseGrey">Minimum Received</Body4>
                          </div>
                          {isValidatingQuote ? (
                            <>
                              <div className="flex h-[14.08px] w-[100px] items-center">
                                <Skeleton className="h-[14.08px] w-full rounded bg-baseGrey" />
                              </div>
                            </>
                          ) : (
                            <Body4 className="text-white">
                              {minimumReceived && tokenOutInfo
                                ? `${numberWithCommas(minimumReceived.toSignificant(6))} ${tokenOutInfo.symbol ?? '--'}`
                                : '--'}
                            </Body4>
                          )}
                        </div>
                        <div className="flex items-center justify-between">
                          <Body4 className="text-baseGrey">Trade Route</Body4>
                          <Button
                            variant="light"
                            className="m-0 h-fit w-fit min-w-fit gap-0 p-1"
                            disableAnimation
                            disableRipple
                            onPress={() => onOpenModal(MODAL_LIST.TRADE_ROUTE)}
                          >
                            <Icon icon="flowbite:expand-outline" color="#FFFFFF" fontSize={18} />
                          </Button>
                        </div>
                      </motion.div>
                    </div>
                    <Spacer y={4} />
                  </>
                )}
              </>
            )}

            {activePanel === SwapPanel.SelectTokenIn && (
              <TokenSelector
                swapCardRef={swapCardRef}
                onSelectToken={setTokenIn}
                onBack={() => setActivePanel(SwapPanel.Swap)}
              />
            )}

            {activePanel === SwapPanel.SelectTokenOut && (
              <TokenSelector
                swapCardRef={swapCardRef}
                onSelectToken={setTokenOut}
                onBack={() => setActivePanel(SwapPanel.Swap)}
              />
            )}

            {activePanel === SwapPanel.SwapSettings && (
              <SwapSettings swapCardRef={swapCardRef} onBack={() => setActivePanel(SwapPanel.Swap)} />
            )}
          </div>
        </div>
      </main>
      <ModalTradeRoute
        isOpen={globalModal === MODAL_LIST.TRADE_ROUTE && isModalOpen}
        onOpenChange={onOpenChangeModal}
        onClose={onCloseModal}
        srcCoinType={tokenIn}
        dstCoinType={tokenOut}
        readableAmountIn={numberWithCommas(typedAmountIn)}
        readableAmountOut={readableAmountOut}
        rawAmountIn={fractionalAmountIn?.numerator?.toString()}
        paths={paths}
      />
    </>
  )
}
