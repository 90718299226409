import { AptosWalletContextState, useAptosWallet as abc } from '@razorlabs/wallet-kit'
import { useEffect, useMemo } from 'react'

export default function useMovementWallet(): AptosWalletContextState {
  const aptosWallet = abc()

  useEffect(() => {
    if (aptosWallet.connected && aptosWallet.account?.address !== undefined) {
      // void aptosWallet.disconnect()
    }
  }, [aptosWallet])

  return useMemo(() => {
    const connected = Boolean(aptosWallet.account?.address && aptosWallet.connected)
    return {
      ...aptosWallet,
      connected,
      account: connected ? aptosWallet.account : undefined,
    }
  }, [aptosWallet])
}
